<template>
  <v-container
    id="quote"
    tag="section"
    fluid
    class="light-blue lighten-5 px-1"
  >
    <canadian-citizen
      :ineligible-dialog="ineligibleDialog"
      @closeInEligibleDialog="closeDialog"
    />
    <v-overlay v-if="loading && buttonClicked">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </v-overlay>
    <v-card class="style-card">
      <v-card-text>
        <v-row class="d-flex justify-center">
          <v-col
            class="d-flex justify-center text-h2 font-weight-light text-center primary--text mb-6 mt-6"
          >
            {{ $t("quotePage.quotePremium") }}
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="col-md-10">
            <v-form
              ref="getQuoteForm"
            >
              <validation-observer
                ref="quoteobserver"
                v-slot="{ invalid }"
              >
                <v-row class="d-flex justify-center">
                  <v-col class="pb-0">
                    <p>{{ $t("caTerm.basicInfo.sexAtBirth") }}</p>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="requiredOption"
                    >
                      <v-radio-group
                        v-model="user.sex"
                        :error-messages="errors"
                        class="mb-4"
                        row
                      >
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          value="M"
                          :label="$t('caTerm.basicInfo.male')"
                          @click="updateSex('M')"
                        >
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          value="F"
                          :label="$t('caTerm.basicInfo.female')"
                          @click="updateSex('F')"
                        >
                        </v-radio>
                      </v-radio-group>
                    </validation-provider>
                    <p>{{ $t("caTerm.basicInfo.dateOfBirthQuestion") }}</p>
                    <v-row>
                      <v-col
                        cols="11"
                        sm="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Date of birth"
                          :rules="{
                            required: true,
                            validateDate:[minDate, maxDate, $t('caTerm.basicInfo.dateOfBirth')]
                          }"
                        >
                          <v-text-field
                            v-model="user.dob"
                            v-mask="'##/##/####'"
                            outlined
                            :label="$t('dateFormat')"
                            :error-messages="errors"
                            clearable
                            class="date-picker"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <div
                      v-for="(question, index) in surveyQuestions"
                      :key="index"
                    >
                      <p>{{ question.text }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="requiredOption"
                      >
                        <!-- Using v-model directly on question.answer -->
                        <v-radio-group
                          v-if="question.type === 'radio'"
                          v-model="question.answer"
                          :error-messages="errors"
                          row
                        >
                          <v-radio
                            v-for="(option, optionIndex) in $t('forms.booleanOptions')"
                            :key="optionIndex"
                            :label="option.label"
                            :value="option.value"
                            @click="updateSurveyQuestions(option.value, index)"
                          >
                          </v-radio>
                        </v-radio-group>
                      </validation-provider>
                    <!-- Conditional Questions -->
                    </div>
                  </v-col>
                </v-row>
                <v-card-actions class="d-flex justify-center">
                  <v-spacer></v-spacer>
                  <v-btn
                    x-large
                    color="primary"
                    class="font-weight-bold mr-0 quote-btn"
                    :disabled="invalid || ineligibleDialog"
                    @click="getQuotePremiuim"
                  >
                    {{ $t('quotePage.getQuote') }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </validation-observer>
            </v-form>

            <v-card
              v-if="getPremium && !ineligibleDialog"
              ref="premiumContainer"
              min-height="300"
              elevation="0"
              class="style-card-premium"
            >
              <div
                class="px-0 text-center"
              >
                <p class="estimate-subtitle">
                  {{ $t('caTerm.premiumQuote.yourQuote') }}
                  <span
                    class="font-weight-bold primary--text"
                  > {{ monthlyPremium }} /{{ $t('common.month') }}*</span>
                </p>

                <v-row justify="center">
                  <v-col
                    cols="11"
                    sm="10"
                    md="10"
                  >
                    <v-card
                      elevation="0"
                      class="text-center plan-card pa-6 mx-0"
                    >
                      <div
                        class="d-inline-flex align-center justify-center mt-2 mb-2"
                      >
                        <div class="switch-text ml-2 color-blue">
                          {{ $t('caTerm.premiumQuote.policyOptions') }}
                        </div>
                      </div>
                      <v-row class="d-flex align-center mt-2">
                        <v-col
                          cols="4"
                          class="px-0"
                        >
                          <span
                            :class="
                              mobileDevice
                                ? 'offer-text-mobile'
                                : 'offer-text'
                            "
                          >{{ $t('caTerm.premiumQuote.premium') }}
                          </span>
                          <br />
                          <v-chip
                            class="primary--text mt-4"
                            outlined
                            color="primary"
                          >
                            <span
                              :class="
                                mobileDevice
                                  ? 'chip-text-mobile'
                                  : 'chip-text'
                              "
                            >{{ monthlyPremium }}</span>
                          </v-chip>
                        </v-col>
                        <v-col
                          cols="4"
                          class="px-0"
                        >
                          <div
                            :class="
                              mobileDevice
                                ? 'offer-text-mobile'
                                : 'offer-text'
                            "
                          >
                            {{ $t('caTerm.premiumQuote.faceAmount') }}
                          </div>
                          <v-chip
                            class="gray--text mt-4"
                            outlined
                            color="primary"
                          >
                            <span
                              :class="
                                mobileDevice
                                  ? 'chip-text-mobile'
                                  : 'chip-text'
                              "
                            >{{ formattedCoverageAmount }}</span>
                          </v-chip>
                        </v-col>
                        <v-col
                          cols="4"
                          class="px-0"
                        >
                          <div
                            :class="
                              mobileDevice
                                ? 'offer-text-mobile'
                                : 'offer-text'
                            "
                          >
                            {{ $t('caTerm.premiumQuote.termLength') }}
                          </div>
                          <v-chip
                            class="gray--text mt-4"
                            outlined
                            color="primary"
                          >
                            <span
                              :class="
                                mobileDevice
                                  ? 'chip-text-mobile'
                                  : 'chip-text'
                              "
                            >{{ user.term }} {{ $t('common.years') }}
                            </span>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row class="d-felx justify-center">
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Term"
                          rules="required"
                        >
                          <v-select
                            v-model="user.term"
                            :items="allowedTermLengths"
                            outlined
                            :label="$t('caTerm.approval.term')"
                            color="primary"
                            :error-messages="errors"
                            @change="getQuotePremiuim"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="5"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Coverage"
                          rules="required"
                        >
                          <v-select
                            v-model="user.coverage"
                            :items="coverageAmounts"
                            item-text="text"
                            item-value="value"
                            outlined
                            :label="$t('caTerm.premiumQuote.coverageAmount')"
                            color="primary"
                            :error-messages="errors"
                            @change="getQuotePremiuim"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col
                        cols="11"
                        class="text-center subtitle black--text font-size-16 style-disclaimer"
                      >
                        {{ $t('caTerm.premiumQuote.disclaimer') }}
                      </v-col>
                      <v-col class="d-flex justify-center">
                        <v-btn
                          x-large
                          color="primary"
                          class="font-weight-bold mr-0 d-flex justify-center"
                          @click="$router.push('/canadianterm/survey/pre-application')"
                        >
                          {{ $t('common.buttons.continue') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>

  import moment from 'moment'
  import router from '@/router'
  import { mapMutations } from 'vuex'
  import { localCurrency } from '@/utils/common'
  import CanadianCitizen from '../../../components/base/CanadianCitizen'
  import { DateTime } from 'luxon'
  import {
    FACE_AMOUNTS
  } from '../../../consts/ca-term'
  import { gtag_report_conversion } from '../../../utils/common'

  export default {
    components: {
      CanadianCitizen
    },
    data () {
      return {
        user: {
          dob: null,
          sex: null,
          coverage: 100000,
          term: 10
        },
        ineligibleDialog: false,
        buttonClicked: false,
        maxDate: moment().subtract(18, 'years').format('YYYY-MM-DD'),
        minDate: moment().subtract(69, 'years').format('YYYY-MM-DD'),
        dobDialog: false,
        activeBirthDatePicker: null,
      }
    },
    computed: {
      isSmoker () {
        return this.$store.getters?.getQuoteSurveyQues?.[1]?.answer
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      sex () {
        return this.$store.getters?.getQuoteCustomerSex
      },
      dob () {
        return this.$store.getters?.getQuoteCustomerDob
      },
      getAge () {
        return moment().diff(this?.dob, 'years') ?? null
      },
      premiumPayload () {
        return {
          gender: this.sex,
          smoker: this.isSmoker,
          age: this.getAge,
          product: this.user.term,
          amount: this.user.coverage || 100000,
        }
      },
      loading () {
        return this.$store.getters.getloading
      },
      monthlyPremium () {
        const prem = this.$store.getters.getProperty('CATermPremium')?.monthlypremium || 0
        return localCurrency(this.$i18n.locale, prem)
      },
      getPremium () {
        return this.$store.getters.getProperty('CATermPremium')?.monthlypremium || 0
      },
      surveyQuestions () {
        return this.$store.getters.getQuoteSurveyQues
      },
      formattedCoverageAmount () {
        return localCurrency(this.$i18n.locale, this.user.coverage)
      },
      userAge () {
        const age = moment().diff(moment(this.user.dob, 'MM/DD/YYYY'), 'years')
        return age
      },
      allowedTermLengths () {
        const maxAgeWithTerm = 80
        const validTermLengths = [10, 15, 20]
        return validTermLengths.filter(term => this.userAge + term <= maxAgeWithTerm)
      },
      coverageAmounts () {
        if (this.userAge >= 18 && this.userAge <= 50) {
          return FACE_AMOUNTS
        } else if (this.userAge > 50) {
          return FACE_AMOUNTS.filter(amountObj => amountObj.value <= 500000)
        }
        return []
      }
    },

    watch: {
      dobDialog (val) {
        this.isCalenderClicked = true
        if (val) {
          this.activeBirthDatePicker = 'YEAR'
          this.user.dob = this.maxDate
        }
      },
      'user.dob' (val) {
        this.updateDob(val)
      },
      loading (val) {
        if (!val) {
          setTimeout(() => {
            const element = document.querySelector('.style-card-premium')
            element.scrollIntoView({
              behaviour: 'smooth',
              block: 'start'
            })
          }, 0)
        }
      }
      // isCandian (val) {
      //   if (!val) {
      //     this.ineligibleDialog = true
      //   } else {
      //     this.ineligibleDialog = false
      //   }
      // }
    },
    mounted () {
      const updatedQuestions = [...this.$store.getters.getQuoteSurveyQues]
      const setAnswerNull = updatedQuestions.map((item, index) => {
        item.answer = null
        if (index === 0) {
          item.text = this.$t('quotePage.areYouCandian')
        } else {
          item.text = this.$t('quotePage.last12Months')
        }
        return item
      })
      this.setQuoteSurveyQues([...setAnswerNull])
      this.updateDob('')
      this.updateSex('')
      this.updateQuoteTerm(10)
      this.updateQuoteCoverage(100000)
      this.$store.dispatch('setCATermPremium', null)
    },
    methods: {
      ...mapMutations(['setQuoteCustomerDob', 'setQuoteCustomerSex', 'setQuoteSurveyQues', 'setQuoteTerm', 'setQuoteCoverage']),
      updateDob (val) {
        this.setQuoteCustomerDob(val)
      },
      updateSex (val) {
        this.setQuoteCustomerSex(val)
      },
      updateQuoteTerm (val) {
        this.setQuoteTerm(val)
      },
      updateQuoteCoverage (val) {
        this.setQuoteCoverage(val)
      },
      updateSurveyQuestions (answer, index) {
        const updatedQuestions = [...this.$store.getters.getQuoteSurveyQues]
        updatedQuestions[index].answer = answer
        this.setQuoteSurveyQues(updatedQuestions)
        if (index === 0) {
          this.ineligibleDialog = !answer
        }
      },
      async getQuotePremiuim () {
        this.buttonClicked = true
        await this.$store.dispatch('getCATermPremium', this.premiumPayload)
        this.updateQuoteTerm(this.user.term)
        this.updateQuoteCoverage(this.user.coverage)
        const send_to = process.env.VUE_APP_GET_QUOTE
        gtag_report_conversion(this.$gtag, send_to)
      },
      router () {
        return router
      },
      closeDialog () {
        this.ineligibleDialog = false
      }
    },

  }
</script>
<style scoped>
::v-deep .date-picker input{
  font-size: 16px!important;
}
::v-deep .v-label{
  color: rgba(0, 0, 0, 0.67)!important;
  font-size: 16px!important;
}
p {
  font-size: 16px;
}
.style-card{
    width: 900px;
  border-radius: 26px;
  margin: 30px auto 50px;
}
::v-deep .v-input--selection-controls.v-input .v-label {
  margin-bottom: 0 !important;
  color: black;
}
::v-deep .v-card__text {
    padding-bottom: 80px;
    font-size: 14px;
}
.plan-card {
  background-color: rgba(0, 161, 183, 0.1) !important;
  border: 2px solid #00a1b7 !important;
  border-radius: 6px !important;
}
.style-card-premium{
    margin-top: 20px;
}
@media screen and (min-width: 768px)  and (max-width: 1024px) {
  ::v-deep .v-card__text{
      width: 450px!important;
    margin: auto auto 50px;
  }
}
.font-size-16{
  font-size: 16px;
}
.color-blue{
  font-size: 19px;
  color: #00a1b7 !important;
}
::v-deep .v-btn{
  border-radius: 12px;
}
.quote-btn {
  padding: 18px!important;
}
.estimate-subtitle{
  margin-bottom: 0;
}
.style-disclaimer{
  margin-top: -30px;
}
::v-deep .v-messages__message {
  hyphens : none;
}
</style>
